import React, { createContext, useState } from "react";

// Crie o contexto
const BlogContext = createContext();

// Crie o provedor do contexto
export const BlogProvider = ({ children }) => {
  const [clickedBlog, setClickedBlog] = useState(null);

  return (
    <BlogContext.Provider value={{ clickedBlog, setClickedBlog }}>
      {children}
    </BlogContext.Provider>
  );
};

export default BlogContext;
