import { useLanguage } from "../context/LanguageContext";
import translations from "../i18n/translations.json";

export const useTranslation = () => {
  const { language, changeLanguage } = useLanguage();

  const t = (key) => {
    const keys = key.split(".");
    return keys.reduce((obj, k) => (obj && obj[k] ? obj[k] : null), translations[language]);
  };

  return { t, changeLanguage, language };
};
