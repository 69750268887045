import React from "react";
import styles from "./cv.module.css";
import { useLanguage } from "../../context/LanguageContext";
import ReactGA from "react-ga4";
import Avatar from "../../components/Avatar/Avatar";

const translations = {
  en: {
    profile: "PROFESSIONAL PROFILE",
    profileContent: `Systems Analyst and Full Stack Web Developer with intermediate level experience. Passionate about languages, I research data sovereignty and have been looking to deepen my knowledge in Machine Learning applied to web projects. Proficient in JavaScript frameworks such as ReactJS, NodeJS, with experience in SQL, NoSQL databases, and cloud services such as AWS and GCP. Violinist and music teacher with extensive experience in violin teaching and music production.`,
    experiences: "PROFESSIONAL EXPERIENCES",
    experiencesContent: {
      1: {
        role: "Web Product Implementation Specialist: Bilingual (Spanish)",
        company: "Cognizant (Google)",
        time: "Full-time - Remote | Jun 2021 - Dec 2022",
        content: `We provided technical and specialized support for users of Google's sales and marketing platforms, such as Google Ads, Google Analytics, and Merchant Center.
        We implemented essential solutions to enable the full potential of some advanced features on these platforms, using Javascript/HTML scripts or Google Tag Manager.
        The high-level, high-performance team delivered with expertise the company's primary goal: an amazing experience for Google's customers.
        `,
      },
      2: {
        role: "Front-End Developer",
        company: "Freelancer",
        time: "August 2020 - Present",
        content: `Led the development of responsive and dynamic web applications using JavaScript, HTML, and CSS.
        Utilized React.js, Next.js, and other frameworks to build interactive user interfaces.
        Participated in several freelance projects involving PHP.
        Continuously enhancing expertise in Machine Learning and Data Science for use in modern web applications.
        Recent projects: https://www.ankaibrasil.com.br, https://calculadora-test.netlify.app/, https://www.ocupaif.netlify.app.
        `,
      },
      3: {
        role: "Violinist and Music Teacher",
        company: "Violino Viajado - Self Employed",
        time: "August 2011 - Present",
        content: `Developed and implemented a popular violin teaching methodology based on the sociocultural context of the students.
        Expanded the musical perception of students, allowing both new and experienced violinists to better understand how to perform in various musical genres.
        `,
      },
      4: {
        role: "Music Producer",
        company: "Ed Amareama | Brasília - Jerusalem - São Paulo",
        time: "2019 - Present",
        content: `Focused on bringing together artists from all over the world, channeling new connections and collaborations for a borderless artistic scene.`,
      },
    },
    education: "EDUCATION",
    educationContent: {
      1: {
        title: `Bachelor's in Systems Analysis`,
        school: `IFSP Campus Caraguatatuba`,
        time: `April 2021 - Present (Expected: Dec 2024)`,
        content: `Specialized in software development.
        Acquired profound knowledge in systems analysis.
        Gained extensive knowledge in system documentation.
        Studied and implemented various design patterns.
        Explored the complexities of software architecture and testing.`,
      },

      2: {
        title: `The Complete JavaScript Course 2023: From Zero to Expert`,
        school: `Jonas Schmedtmann - Udemy`,
        time: ` March 2018 - November 2019`,
        content: `Updated annually - 68h30min of on-demand video.`,
      },
      3: {
        title: `Camera Operator for Cinema and TV`,
        school: `IFB - Federal Institute of Brasília`,
        time: `July 2020 - December 2020`,
        content: ``,
      },
      4: {
        title: `C# Developer`,
        school: `SENAI-DF `,
        time: `March 2020 - September 2020`,
        content: ``,
      },
      5: {
        title: `React - The Complete Guide (incl Hooks, React Router, Redux)`,
        school: `Udemy`,
        time: `October 2022 - July 2023`,
        content: ``,
      },
      6: {
        title: `Learn Python 3`,
        school: `Codecademy`,
        time: `August 2018 - September 2018`,
        content: ``,
      },
      7: {
        title: `Bachelor's in Japanese Language and Literature`,
        school: `UnB - University of Brasília`,
        time: `July 2006 - December 2011 (On hold)`,
        content: ``,
      },
    },

    languages: {
      title: "LANGUAGES",
      content: [
        { Portuguese: "Native" },
        { English: "fluent" },
        { Spanish: "fluent" },
        { French: "advanced" },
        { Japanese: "basic" },
        { Hebrew: "basic" },
      ],
    },
    technologies: "TECHNOLOGIES",
    techList: `Javascript, HTML, CSS, ReactJS, NextJS, React Native, NodeJS, Python, Flask, Generative AI API, SQL, PostgreSQL, MongoDB,  AWS EC2, AWS RDS, AWS S3, Microservices, REST API, RESTFul API, CMS variados, Web development, Customer Experience, Agile Frameworks.`,
  },

  pt: {
    profile: "SOBRE MIM",
    profileContent: `Analista de Sistemas e desenvolvedor web full stack de nível pleno apaixonado por idiomas, pesquiso sobre soberania de dados e tenho buscado aprofundar meus conhecimentos em Machine Learning aplicados a projetos web. Domino frameworks Javascript como ReactJS, NodeJS, também tenho experiência com bancos de dados SQL, NoSQL e serviços de cloud como AWS e GCP.   Violinista e professor de música com vasta experiência em ensino de violino e produção musical.`,
    experiences: "EXPERIÊNCIAS PROFISSIONAIS",
    experiencesContent: {
      1: {
        role: "Especialista em Implementação de Produtos Web: Bilíngue (Espanhol)",
        company: "Cognizant (Google)",
        time: "Tempo integral - Remoto | Jun 2021 - Dez 2022",
        content: `Oferecemos suporte técnico e especializado para usuários das plataformas de vendas e marketing do Google, como Google Ads, Google Analytics e Merchant Center. \n
        Implementamos soluções essenciais para habilitar o máximo potencial potencial dessas plataformas, através de scripts em Javascript/HTML ou Google Tag Manager. \n
        A equipe de alto nível e de alto desemppenho entrega com virtuosismo o objetivo número 1 da empresa: uma experiência incrível para o cliente do Google.`,
      },
      2: {
        role: "Desenvolvedor Front-End",
        company: "Autônomo",
        time: "Agosto 2020 - Atual",
        content: `Liderou o desenvolvimento de aplicações web responsivas e dinâmicas usando JavaScript, HTML e CSS.
          Utilizou React.js, Next.js e outros frameworks para construir interfaces de usuário interativas.
          Participou de alguns projetos freelance envolvendo PHP e desenvolvimento de sistemas backend.
          Aprimorando continuamente a expertise em Machine Learning e Ciência de Dados para uso em aplicações web modernas e sistemas de recomendação.
          Projetos recentes: https://www.ankaibrasil.com.br, https://calculadora-test.netlify.app/, https://www.ocupaif.netlify.app.`,
      },
      3: {
        role: "Violinista e Professor de Música",
        company: "Violino Viajado - Autônomo",
        time: "Agosto 2011 - Atual",
        content: `Desenvolveu e Implementou uma metodologia de ensino de violino popular baseada no
        contexto sociocultural dos alunos.
        Expandiu a percepção musical dos alunos, permitindo que violinistas novos ou experientes
        entendam melhor como se apresentar em diversos gêneros musicais.`,
      },
      4: {
        role: "Produtor Musical",
        company: "Ed Amareama Brasília - Jerusalém - São Paulo",
        time: "2019 - Até agora",
        content: `Focado em reunir artistas de todo o mundo canalizando novas conexões e colaborações
      para uma cena artística sem fronteiras.`,
      },
    },
    education: "EDUCAÇÃO",
    educationContent: {
      1: {
        title: `Tecnólogo em Análise de Sistemas`,
        school: `IFSP Campus Caraguatatuba`,
        time: `Abril 2021 - Até agora (previsão: Dez 2024)`,
        content: `Especializado em desenvolvimento de software. Adquiriu profundo e abrangente conhecimento em análise e documentação de sistemas. Estudou e implementou diversos tipos de sistemas em linguagens como Java, C++, NodeJS utilizando diferentes padrões de projeto. Explorou as complexidades da arquitetura de software e a importância dos testes.`,
      },

      2: {
        title: `The Complete JavaScript Course 2023: From Zero to Expert`,
        school: `Jonas Schmedtmann - Udemy`,
        time: `Março 2018 - Novembro 2019 (atualizado anualmente) - 68h30min de vídeo sob demanda.`,
        content: ``,
      },
      3: {
        title: `Operador de Câmera para Cinema e TV`,
        school: `IFB - Instituto Federal de Brasília`,
        time: `Julho 2020 - Dezembro 2020`,
        content: ``,
      },
      4: {
        title: `Desenvolvedor C#`,
        school: `SENAI-DF `,
        time: `Março de 2020 - Setembro de 2020`,
        content: ``,
      },
      5: {
        title: `React - The Complete Guide (incl Hooks, React Router, Redux)`,
        school: `Udemy`,
        time: `Outubro 2022 - Julho 2023`,
        content: ``,
      },
      6: {
        title: `Learn Python 3`,
        school: `Codecademy`,
        time: `Agosto 2018 - Setembro 2018`,
        content: ``,
      },
      7: {
        title: `Licenciatura em Língua e Literatura Japonesa`,
        school: `UnB - Universidade de Brasília`,
        time: `Julho 2006 - Dezembro 2011 (trancado)`,
        content: ``,
      },
    },

    languages: {
      title: "IDIOMAS",
      content: [
        { Portugues: "Nativo" },
        { Inglês: "fluente" },
        { Espanhol: "fluente" },
        { Francês: "avançado" },
        { Japonês: "básico" },
        { Hebraico: "básico" },
      ],
    },
    technologies: "TECNOLOGIAS",
    techList: `Javascript, HTML, CSS, ReactJS, NextJS, React Native, NodeJS, Python, Flask, Generative AI API, SQL, PostgreSQL, MongoDB,  AWS EC2, AWS RDS, AWS S3, Microservices, REST API, RESTFul API, CMS variados, Web development, Customer Experience, Agile Frameworks.`,
  },
};

const resumeLinks = {
  pt: "https://drive.google.com/uc?export=download&id=1cGSazUNUVx5BmhlvDxJbIzFGdvDPaBsM",
  en: "https://drive.google.com/uc?export=download&id=1-i7EzfuE4s0KVSzdRwa5umcvfmM7Uc_c",
};

function CV() {
  const { language, changeLanguage } = useLanguage();

  const trackDownloadClick = () => {
    ReactGA.event({
      category: "Download",
      action: "Download CV",
      label: `Language: ${language}`,
    });
  };

  return (
    <div className={styles.cvContainer}>
      <div className={styles.buttons}>
        <button
          className={styles.languageSwitch}
          onClick={() => changeLanguage(language === "pt" ? "en" : "pt")}
        >
          {language === "pt" ? "Switch to English" : "Mudar para Português"}
        </button>
        <a
          href={resumeLinks[language]}
          download
          onClick={trackDownloadClick} // Add event tracking to download button
        >
          <button className={styles.downloadButton}>
            {language === "pt"
              ? "Baixar Currículo em PDF"
              : "Download CV in PDF"}
          </button>
        </a>
      </div>
      <div className={styles.avatarContainer}>
      <h1 className={styles.titleAnimated}>DIEGO SOARES</h1>
        <Avatar />
      </div>
      <section className={styles.section}>
        <h2 className={styles.subtitle}>{translations[language].profile}</h2>
        <p className={styles.content}>
          {translations[language].profileContent}
        </p>
      </section>

      <section className={styles.section}>
        <h2 className={styles.subtitle}>
          {translations[language].experiences}
        </h2>
        <div className={styles.content}>
          {Object.values(translations[language].experiencesContent).map(
            (experience, index) => (
              <div key={index} className={styles.experience}>
                <h3>
                  {experience.role} - {experience.company}
                </h3>
                <p>{experience.time}</p>
                <p>{experience.content}</p>
              </div>
            )
          )}
        </div>
      </section>

      <section className={styles.section}>
        <h2 className={styles.subtitle}>{translations[language].education}</h2>
        <div className={styles.educationContent}>
          {Object.values(translations[language].educationContent || {}).map(
            (education, index) => (
              <div key={index} className={styles.education}>
                <h3 className={styles.h3}>
                  {education.title} - {education.school}
                </h3>
                <p>{education.time}</p>
                <p>{education.content}</p>
              </div>
            )
          )}
        </div>
      </section>

      <section className={styles.section}>
        <h2 className={styles.subtitle}>
          {translations[language].languages.title}
        </h2>
        <ul className={styles.languageList}>
          {translations[language].languages.content.map((lang, index) => (
            <li className={styles.language} key={index}>
              {Object.keys(lang)[0]}: {Object.values(lang)[0]}
            </li>
          ))}
        </ul>
      </section>

      <section className={styles.section}>
        <h2 className={styles.subtitle}>
          {translations[language].technologies}
        </h2>
        <p className={styles.content}>{translations[language].techList}</p>
      </section>
    </div>
  );
}

export default CV;
