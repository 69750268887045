import { useEffect, useRef } from 'react';

const usePortal = (id) => {
  const rootElemRef = useRef(document.createElement('div'));

  useEffect(() => {
    const parentElem = document.querySelector(`#${id}`);
    const { current } = rootElemRef;

    if (!parentElem) {
      const newParentElem = document.createElement('div');
      newParentElem.setAttribute('id', id);
      document.body.appendChild(newParentElem);
      newParentElem.appendChild(current);
    } else {
      parentElem.appendChild(current);
    }

    return () => {
      current.remove();
      if (parentElem && parentElem.childNodes.length === 0) {
        parentElem.remove();
      }
    };
  }, [id]);

  return rootElemRef.current;
};

export default usePortal;
