import React, { useState } from "react";
import { useLanguage } from "../../context/LanguageContext";
import styles from "./LanguageSwitchButton.module.css";

import brFlag from "../../assets/images/br-flag.png";
import ukFlag from "../../assets/images/uk-flag.png";
import menuIcon from "../../assets/images/translate-icon.png";  // Ícone de menu para abrir o slide

function LanguageSwitchButton() {
  const { language, changeLanguage } = useLanguage();
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  return (
    <div className={styles.container}>
      <img
        src={menuIcon}
        alt="menu icon"
        className={styles.menuIcon}
        onClick={toggleVisibility}
      />
      <div
        className={`${styles.buttonWrapper} ${isVisible ? styles.visible : ""}`}
      >
        <button
          className={styles.button}
          onClick={() => {
            changeLanguage(language === "pt" ? "en" : "pt");
            setIsVisible(false); // Esconder o menu após a seleção do idioma
          }}
        >
          <img
            src={language === "pt" ? ukFlag : brFlag}
            alt="country flag"
            className={styles.flag}
          />{" "}
          {language === "pt" ? "English" : "Português"}
        </button>
      </div>
      <button
        className={styles.desktopButton}
        onClick={() => changeLanguage(language === "pt" ? "en" : "pt")}
      >
        <img
          src={language === "pt" ? ukFlag : brFlag}
          alt="country flag"
          className={styles.flag}
        />{" "}
        {language === "pt" ? "English" : "Português"}
      </button>
    </div>
  );
}

export default LanguageSwitchButton;
