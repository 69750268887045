import React, { Suspense, lazy } from "react";
import styles from "./Music.module.css";

const LazyAudioPlayer = lazy(() =>
  import("../../components/AudioPlayer/index")
);

const playlist = [
  {
    id: 1,
    title: "Vem pra cá",
    artist: "Diego Cosamores",
    path: "/assets/music/vem-pra-ca.mp3",
    video: "/assets/music/vem-pra-ca-teaser.mp4",
  },
  {
    id: 2,
    title: "Possa ser",
    artist: "Diego Cosamores",
    path: "/assets/music/possa-ser.wav",
    cover: "/assets/music/possa-ser.png",
  },
  {
    id: 3,
    title: "Baião Cigano",
    artist: "Diego Cosamores",
    path: "/assets/music/baio-cigano.wav",
    cover: "/assets/images/cat-violin.jpeg",
  },
  {
    id: 4,
    title: "Quebra Quenga",
    artist: "Diego Cosamores feat. Dominguinhos",
    path: "/assets/music/quebra-quenga.mp3",
    cover: "assets/images/violin-goat.webp",
  },
  {
    id: 5,
    title: "Homenagem a Chiquinho do Acordeon",
    artist: "Diego Cosamores feat. Dominguinhos",
    path: "/assets/music/homenagem-a-chiquinho-do-acordeon.mp3",
    cover: "/assets/music/cover.jpg",
  },
  {
    id: 6,
    title: "Trenzinho Caipira",
    artist: "Diego Cosamores feat. Villa Lobos",
    path: "/assets/music/trenzinho-caipira.mp3",
    cover: "/assets/music/cover.jpg",
  },
  {
    id: 7,
    title: "Peste da Dança",
    artist: "Diego Cosamores feat. Teko Porã",
    path: "/assets/music/peste-da dança.wav",
    cover: "/assets/music/cover.jpg",
  },
  {
    id: 8,
    title: "Pasaros da cachoera",
    artist: "Diego Cosamores",
    path: "/assets/music/pasaro-da-cachoera.mp3",
    video: "/assets/music/pasaro-da-cachoera-teaser.mp4",
  },
  {
    id: 9,
    title: "Quando eu acordar cedinho",
    artist: "Diego Cosamores",
    path: "/assets/music/quando-eu-acordar-cedinho.mp3",
    cover: "/assets/music/cover.jpg",
  },
  {
    id: 10,
    title: "O Ovo",
    artist: "Diego Cosamores feat. Hermeto Paschoal",
    path: "/assets/music/o-ovo.mp3",
    cover: "/assets/images/violin-bird.png",
  },
];

const MusicPage = () => {
  return (
    <div className={styles.musicPage}>
      <h1 className={styles.title}>MUSIC</h1>
      <Suspense fallback={<div>Loading player...</div>}>
        <LazyAudioPlayer playlist={playlist} className={styles.AudioPlayer} />
      </Suspense>
    </div>
  );
};

export default MusicPage;
