import styles from './ProjectCard.module.css'

function ProjectCard({ url, title }) {
  return (
      <div className={styles.projectCard}>
          <a href={url} target="_blank" rel="noopener noreferrer" className={styles.link}><h3 className={styles.title}>{title}</h3></a>
          <iframe 
              src={url} 
              title={title}
              width="100%" 
              height="400" 
              frameBorder="0" 
              loading="lazy" // This ensures that the iframe content is only loaded when it's about to be scrolled into view
              className={styles.iframe}
          ></iframe>

      </div>
  );
}

export default ProjectCard;
