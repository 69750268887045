import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import styles from "./BlogPage.module.css";
import blogsData from "../../assets/data/blogData.json";
import BlogContext from "../../context/BlogContext";

const BlogPage = () => {
  const { filename } = useParams();
  const { clickedBlog } = useContext(BlogContext); // Acessa o blog clicado a partir do contexto
  const [buttonText, setButtonText] = useState({});

  // Verifica se há um `filename` no URL ou se o `clickedBlog` é acessado diretamente
  let blog;
  if (filename) {
    blog = blogsData.find((b) => b.filename === filename);
  } else {
    blog = clickedBlog;
  }

  if (!blog) {
    return <div>Blog not found</div>;
  }

  const pdfPath = blog.filename ? `/assets/data/${blog.filename}.pdf` : null;
  const formattedDate = new Date(blog.date).toLocaleDateString('pt-BR', { year: 'numeric', month: 'long', day: 'numeric' });

  // Função para copiar código para a área de transferência
  const copyToClipboard = (code, stepIndex, codeType) => {
    navigator.clipboard.writeText(code)
        .then(() => {
            // Atualiza o texto do botão para "Copied!"
            const updatedButtonText = { ...buttonText, [`${stepIndex}-${codeType}`]: 'Copied!' };
            setButtonText(updatedButtonText);
            
            // Restaura o texto do botão para "Copiar" após 3 segundos
            setTimeout(() => {
                setButtonText(prevState => ({ ...prevState, [`${stepIndex}-${codeType}`]: 'Copiar' }));
            }, 3000);
        })
        .catch((err) => {
            console.error('Falha ao copiar código:', err);
        });
};

const renderSteps = (steps) => {
    return steps.map((step, index) => (
        <div key={index} className={styles.step}>
            <h4>{step.title}</h4>
            <p>{step.description}</p>
            {step.code && (
                <div className={styles.codeBlock}>
                    <pre>
                        <button 
                            className={styles.copyBtn}
                            onClick={() => copyToClipboard(step.code, index, 'code')}
                        >
                            {buttonText[`${index}-code`] || 'Copiar'}
                        </button>
                        {step.code}
                    </pre>
                </div>
            )}
            {step.css && (
                <div className={styles.codeBlock}>
                  <pre>
                        <button 
                            className={styles.copyBtn}
                            onClick={() => copyToClipboard(step.css, index, 'js')}
                        >
                            {buttonText[`${index}-js`] || 'Copiar'}
                        </button>
                        {step.js}
                    </pre>
                    <pre>
                        <button 
                            className={styles.copyBtn}
                            onClick={() => copyToClipboard(step.css, index, 'css')}
                        >
                            {buttonText[`${index}-css`] || 'Copiar'}
                        </button>
                        {step.css}
                    </pre>
                </div>
            )}
            {step.codeDescription && <p>{step.codeDescription}</p>}
        </div>
    ));
};


  return (
    <div className={styles.container}>
      <div className={styles.info}>
        <img src={blog.image} alt={blog.title} className={styles.blogImage} />
        <div className={styles.author}>
          <h3>{blog.title}</h3>
          <small>
            Por {blog.author} em {formattedDate}
          </small>
        </div>
      </div>
      <div>
        Tags:{" "}
        {blog.tags.map((tag) => (
          <span key={tag} className={styles.tag}>
            {tag}
          </span>
        ))}
      </div>
      <p className={styles.notebook}>{blog.notebook && <a href={blog.notebook} target="_blank" rel="noreferrer">Acesse o notebook para testar</a>}</p>
      <div className={styles.content}>{blog.content}</div>
      {pdfPath ? (
        <iframe title={blog.title} className={styles.text} src={pdfPath} />
      ) : (
        // Renderize as etapas se não houver um filename
        blog.steps && renderSteps(blog.steps)
      )}
      {blog.ending ? <div className={styles.ending}>{blog.ending}</div> : null}
    </div>
  );
};

export default BlogPage;
