import styles from "./Soon.module.css";

const Soon = () => {
  return (
    <div className={styles.container}>
      <h1 className={styles.soon}>SOON - EM BREVE</h1>
      <p className={styles.text}>
        While we prepare the blog content, take a look at this amazing accordionist, my beloved friend Marius Manole.
      </p>
      <p className={styles.text}>
        Enquanto preparamos o conteúdo do blog, que tal escutar este grande acordeonista e amigo querido, Marius Manole.
      </p>
      <iframe
        className={styles.video}
        width="560"
        height="315"
        src="https://www.youtube.com/embed/WWZ_7CQC66A?si=PW63WLWoC6y9KCa6"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    </div>
  );
};

export default Soon;
