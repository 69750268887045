import styles from "./Footer.module.css";
import { Link } from "react-router-dom";
import { useTranslation } from "../../hooks/useTranslation"; // Importação do hook de tradução

const Footer = () => {
  const { t } = useTranslation(); 

  return (
    <footer className={styles.footer}>
      <p className={styles.privacy}>
        <Link className={styles.privacy} to="/privacy">
          {t("footer.privacyPolicy")} 
        </Link>
      </p>
      <p className={styles.disclaimer}>
        {t("footer.disclaimer")} 
      </p>
    </footer>
  );
};

export default Footer;
