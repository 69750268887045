import React, { useState, useContext, useCallback } from "react";
import { Link } from "react-router-dom";
import BlogPreview from "../../components/BlogPreview";
import blogsData from "../../assets/data/blogData.json";
import styles from "./Blog.module.css";
import BlogContext from "../../context/BlogContext";
import VerticalSlider from "../../components/VerticalSlider/VerticalSlider";
import { debounce } from 'lodash';
import ReactGA from 'react-ga4'; // Importa o react-ga
import { useTranslation } from "../../hooks/useTranslation";
const debouncedSetFilter = debounce((value, setFilter) => setFilter(value), 300);

function Blog() {
  const [filter, setFilter] = useState("");
  const { setClickedBlog } = useContext(BlogContext);
  const { t } = useTranslation();

  const handleFilterChange = useCallback((value) => {
    debouncedSetFilter(value, setFilter);
  }, []);

  const filteredBlogs = blogsData?.filter((blog) =>
    blog.tags.some((tag) => tag.toLowerCase().includes(filter.toLowerCase()))
  ) || [];

  const handleClick = (blog) => {
    setClickedBlog(blog);
    ReactGA.event({
      category: "blog",
      action: "click",
      label: blog.title,
    });
  };

  return (
    <div className={styles.blogContainer}>
      <h1 className={styles.titleAnimated}>BLOG</h1>
      <input
        type="text"
        placeholder={t("placeholder.filter")}
        onChange={(e) => handleFilterChange(e.target.value)}
        className={styles.input}
      />
      <div className={styles.blogs}>
      <VerticalSlider>

          {filteredBlogs.map((blog) => (
            <Link
              to={blog.filename ? `/blog/${blog.filename}` : `/blog/${blog.title.split(' ').join('-').toLowerCase()}`}
              key={blog.id}
              onClick={() => handleClick(blog)}
            >
              <BlogPreview {...blog} key={blog.id}
 />
            </Link>
          ))}
            </VerticalSlider>

      </div>
    </div>
  );
}

export default Blog;
