import styles from "./BlogPreview.module.css";

function BlogPreview({ title, author, date, image, filename, content, tags }) {
  const formattedDate = new Date(date).toLocaleDateString('pt-BR', { year: 'numeric', month: 'long', day: 'numeric' });

  return (
    <div className={styles.blogPreview}>
      <div className={styles.header}>
        <img src={image} alt={title} className={styles.blogImage} />
        <div className={styles.titleContainer}>
          <small>
            Por {author} em {formattedDate}
          </small>
          <h3>{title}</h3>
        </div>
      </div>
      <p>{content}</p>
      <div>
        <span className={styles.tags}>Tags:</span>{" "}
        {tags.map((tag) => (
          <span key={tag} className={styles.tag}>
            {tag}{" "}
          </span>
        ))}
      </div>
    </div>
  );
}

export default BlogPreview;
