import React, { useState, useRef, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Cube.module.css";
import cvImage from "../../assets/images/cv.webp";
import musicImage from "../../assets/images/music.webp";
import techImage from "../../assets/images/tech.webp";
import blogImage from "../../assets/images/blog.webp";
import Hero from "../NewHero";
import Contact from "./Contact";

export const Btn = ({ path, title, navigation }) => {
  return (
    <button className={styles.btns} onClick={() => navigation(path)}>
      {title}
    </button>
  );
};

const Cube = () => {
  const [rotation, setRotation] = useState({ x: 0, y: 0 });
  const startPositionRef = useRef({ x: 0, y: 0 }); 
  const [isDragging, setIsDragging] = useState(false);
  const [momentum, setMomentum] = useState({ x: 0, y: 0 });
  const [perspective, setPerspective] = useState(800);

  const cubeRef = useRef(null);
  const navigate = useNavigate();
  const friction = 0.95;
  const sensitivity = 2;
  const animationIdRef = useRef(null);
  const dragThreshold = 5;
  const autoStopDelay = 3000;

  useEffect(() => {
    let autoStopTimeout = null;

    const handleAutoStop = () => {
      if (animationIdRef.current) {
        cancelAnimationFrame(animationIdRef.current);
        animationIdRef.current = null;
        setMomentum({ x: 0, y: 0 });
      }
    };

    if (!isDragging && (momentum.x !== 0 || momentum.y !== 0)) {
      autoStopTimeout = setTimeout(handleAutoStop, autoStopDelay);
    }

    return () => clearTimeout(autoStopTimeout);
  }, [isDragging, momentum.x, momentum.y]);

  const startDrag = (e) => {
    setIsDragging(true);
    setMomentum({ x: 0, y: 0 });
    startPositionRef.current = {
      x: e.clientX || e.touches[0].clientX,
      y: e.clientY || e.touches[0].clientY,
    };

    if (animationIdRef.current) {
      cancelAnimationFrame(animationIdRef.current);
      animationIdRef.current = null;
    }

    e.preventDefault();
  };

  const drag = useCallback(
    (e) => {
      if (!isDragging) return;
      e.preventDefault();

      const currentPosition = {
        x:
          e.clientX ||
          (e.touches && e.touches.length > 0 ? e.touches[0].clientX : 0),
        y:
          e.clientY ||
          (e.touches && e.touches.length > 0 ? e.touches[0].clientY : 0),
      };

      const distance = Math.sqrt(
        Math.pow(currentPosition.x - startPositionRef.current.x, 2) +
          Math.pow(currentPosition.y - startPositionRef.current.y, 2)
      );
      if (distance > dragThreshold) {
        const deltaRotation = {
          x: (currentPosition.y - startPositionRef.current.y) / sensitivity,
          y: (currentPosition.x - startPositionRef.current.x) / sensitivity,
        };
        setRotation((prev) => ({
          x: prev.x + deltaRotation.x,
          y: prev.y + deltaRotation.y,
        }));
        setMomentum(deltaRotation);
        startPositionRef.current = currentPosition; 
      }
    },
    [isDragging, sensitivity]
  );

  const endDrag = useCallback(() => {
    setIsDragging(false);

    const continueRotation = () => {
      setRotation((prev) => ({
        x: prev.x + momentum.x,
        y: prev.y + momentum.y,
      }));
      setMomentum((prev) => ({
        x: prev.x * friction,
        y: prev.y * friction,
      }));

      if (Math.abs(momentum.x) > 0.1 || Math.abs(momentum.y) > 0.1) {
        animationIdRef.current = requestAnimationFrame(continueRotation);
      } else {
        cancelAnimationFrame(animationIdRef.current);
        animationIdRef.current = null;
      }
    };

    if (momentum.x !== 0 || momentum.y !== 0) {
      continueRotation();
    }
  }, [friction, momentum.x, momentum.y]);


      
  return (
    <div className={styles.component}>
      <div className={styles.slider}>
        <input
          className={styles.range}
          type="range"
          min="0"
          max="2000"
          value={perspective}
          onChange={(e) => setPerspective(parseInt(e.target.value, 10))}
        />
        <label htmlFor="range" className={styles.label}>
          Move the slider to change perspective
        </label>
      </div>
      <div
        className={styles.container}
        style={{ perspective: `${perspective}px` }}
        onMouseDown={startDrag}
        onMouseMove={drag}
        onMouseUp={endDrag}
        onTouchStart={startDrag}
        onTouchMove={drag}
        onTouchEnd={endDrag}
      >
        <div
          className={styles.cube}
          ref={cubeRef}
          style={{
            transform: `rotateX(${-rotation.x}deg) rotateY(${rotation.y}deg)`,
            transition: animationIdRef.current
              ? "none"
              : "transform 0.2s ease-out",
            touchAction: "none",
          }}
        >
          <div className={`${styles.face} ${styles.front}`}>
            <Hero />
          </div>
          <div className={`${styles.face} ${styles.right}`}>
            <div
              className={`${styles["face-link"]} ${styles.bg}`}
              style={{ backgroundImage: `url(${techImage})` }}
            ></div>
            <Btn path="projects" title="Projects" navigation={navigate} />
          </div>
          <div className={`${styles.face} ${styles.back}`}>
            <div
              className={`${styles["face-link"]} ${styles.bg}`}
              style={{ backgroundImage: `url(${blogImage})` }}
            ></div>
            <Btn path="blog" title="Blog" navigation={navigate} />
          </div>
          <div className={`${styles.face} ${styles.left}`}>
            <div
              className={`${styles["face-link"]} ${styles.bg}`}
              style={{ backgroundImage: `url(${cvImage})` }}
            ></div>
            <Btn path="resume" title="Resume" navigation={navigate} />
          </div>
          <div className={`${styles.face} ${styles.top}`}>
            <div
              className={`${styles["face-link"]} ${styles.bg}`}
              style={{ backgroundImage: `url(${musicImage})` }}
            ></div>
            <Btn path="music" title="Music" navigation={navigate} />
          </div>
          <div className={`${styles.face} ${styles.bottom}`}>
            <Contact />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cube;