import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import styles from "./Header.module.css";
import { useTranslation } from "../../hooks/useTranslation";
import LanguageSwitchButton from "../LanguageSwtchButton/LanguageSwitchButton";

function Header() {
  const { t } = useTranslation(); // Só precisamos de `t` para traduzir os textos
  const location = useLocation();
  const currentPath = location.pathname;

  if (currentPath === "/new") return null;


  return (
    <header className={styles.header}>
      <nav>
        <NavLink to="/" className={currentPath === "/" ? styles.activeLink : ""}>
          {t("header.home")}
        </NavLink>
        <NavLink to="/resume" className={currentPath === "/resume" ? styles.activeLink : ""}>
          {t("header.resume")}
        </NavLink>
        <NavLink to="/blog" className={currentPath === "/blog" ? styles.activeLink : ""}>
          {t("header.blog")}
        </NavLink>
        <NavLink to="/projects" className={currentPath === "/projects" ? styles.activeLink : ""}>
          {t("header.projects")}
        </NavLink>
        <NavLink to="/music" className={currentPath === "/music" ? styles.activeLink : ""}>
          {t("header.music")}
        </NavLink>
      </nav>
      <div className={styles.languageSwitcher}>
        <LanguageSwitchButton /> 
      </div>
    </header>
  );
}

export default Header;
