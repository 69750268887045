import React from "react";
import "../../assets/styles/styles.css";
import styles from "./Hero.module.css";
import { useTranslation } from "../../hooks/useTranslation"; // Importação do hook de tradução

const Hero = () => {
  const { t } = useTranslation(); 

  return (
    <div className={styles.heroContainer}>
      <div className={styles.titleContainer}>
        <div className={styles.titleBorder}>
          <div className={styles.titleBorder2}>
            <div className={styles.titleBorder3}></div>
          </div>
        </div>
      </div>
      <h1 className={styles.titleAnimated}>
        {t("hero.title")} 
      </h1>
      <p className={styles.subtitle}>
        {t("hero.subtitle")} 
      </p>
    </div>
  );
};

export default Hero;
