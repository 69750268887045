import React from "react";
import imageUrl from "../../assets/images/avatar.jpg";
import styles from "./Avatar.module.css";

const Avatar = () => {
  return (
    <div className={styles.avatarContainer}>
      <img src={imageUrl} alt="Avatar" className={styles.avatar} />
    </div>
  );
};

export default Avatar;
