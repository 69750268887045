import ReactGA from "react-ga4";
import { onCLS, onLCP, onTTFB, onFCP } from "web-vitals";

const sendToAnalytics = ({ name, delta, id }) => {
  ReactGA.event({
    category: "Web Vitals",
    action: name,
    value: Math.round(delta), 
    label: id, 
    nonInteraction: true,
  });
};

onCLS(sendToAnalytics);
onLCP(sendToAnalytics);
onTTFB(sendToAnalytics);
onFCP(sendToAnalytics);

export { sendToAnalytics };