import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "../../hooks/useTranslation";

import cvImage from "../../assets/images/cv.webp";
import musicImage from "../../assets/images/music.webp";
import techImage from "../../assets/images/tech.webp";
import blogImage from "../../assets/images/blog.webp";
import styles from "./MainSections.module.css";

const MainSections = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.sectionsContainer}>
      <section className={styles.cvSection}>
        <img src={cvImage} alt="CV Icon" className={styles.sectionIcon} />
        <h2 className={styles.sectionTitle}>{t("mainSections.cvSection.title")}</h2>
        <p className={styles.sectionContent}>{t("mainSections.cvSection.content")}</p>
        <Link to={t("mainSections.cvSection.url")} className={styles.sectionButton}>
          {t("mainSections.cvSection.button")}
        </Link>
      </section>

      <section className={styles.blogSection}>
        <img src={blogImage} alt="Blog Icon" className={styles.sectionIcon} />
        <h2 className={styles.sectionTitle}>{t("mainSections.blogSection.title")}</h2>
        <p className={styles.sectionContent}>{t("mainSections.blogSection.content")}</p>
        <Link to={t("mainSections.blogSection.url")} className={styles.sectionButton}>
          {t("mainSections.blogSection.button")}
        </Link>
      </section>

      <section className={styles.projectsSection}>
        <img
          src={techImage}
          alt="Projects Icon"
          className={styles.sectionIcon}
        />
        <h2 className={styles.sectionTitle}>{t("mainSections.projectsSection.title")}</h2>
        <p className={styles.sectionContent}>{t("mainSections.projectsSection.content")}</p>
        <Link to={t("mainSections.projectsSection.url")} className={styles.sectionButton}>
          {t("mainSections.projectsSection.button")}
        </Link>
      </section>

      <section className={styles.musicSection}>
        <img src={musicImage} alt="Music Icon" className={styles.sectionIcon} />
        <h2 className={styles.sectionTitle}>{t("mainSections.musicSection.title")}</h2>
        <p className={styles.sectionContent}>{t("mainSections.musicSection.content")}</p>
        <Link to={t("mainSections.musicSection.url")} className={styles.sectionButton}>
          {t("mainSections.musicSection.button")}
        </Link>
      </section>
    </div>
  );
};

export default MainSections;
