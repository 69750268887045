import styles from "./NewMain.module.css";
import bgImage from "../../assets/images/lab-bg.jpg";

import Cube from "../../components/Cube/Cube.js";

function MainPage() {
  return (
    <div className={styles.mainContainer}>
      <div className={styles.sectionsContainer}>
        <Cube />
        <p className={styles.subtitle}>
          Lab is my playgroung where you find some experiments and curious components  {" "}
        </p>
      </div>
      <div className={styles.landingSection}>
        <img
          src={bgImage}
          alt="violin birdw ith open wings background"
          className={styles.backgroundImage}
        />
      </div>
      
    </div>
  );
}

export default MainPage;
