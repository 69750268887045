import ReactGA from "react-ga4";
import {
  FaWhatsapp,
  FaPhone,
  FaEnvelope,
  FaGithub,
  FaLinkedin,
} from "react-icons/fa"; // Using Font Awesome icons for better accessibility

import styles from "./Contact.module.css";

const contactInfo = [
  {
    icon: <FaWhatsapp />,
    label: "WhatsApp",
    href: "https://wa.me/5511941996781",
  },
  { icon: <FaPhone />, label: "Phone", href: "tel:+5511941996781" },
  { icon: <FaEnvelope />, label: "Email", href: "mailto:edamareama@gmail.com" },
  { icon: <FaGithub />, label: "GitHub", href: "https://github.com/Cosamores" },
  {
    icon: <FaLinkedin />,
    label: "LinkedIn",
    href: "https://www.linkedin.com/in/diegocosamores/",
  },
];

const Contact = () => {
  const trackContactClick = (label) => {
    ReactGA.event({
      category: label,
      action: "contact",
    });
  };

  return (
    <div className={styles.contactModal}>
      <h1 className={styles.title}>LET'S TALK</h1>
      <ul className={styles.lista}>
        {contactInfo.map((info) => (
          <li key={info.label} className={styles.li}>
            {info.icon}
            <a
              className={styles.link}
              href={info.href}
              onClick={() => trackContactClick(info.label)}
              aria-label={info.label} // Add aria-label for accessibility
            >{" "}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Contact;
