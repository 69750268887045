import React from 'react';
import '../../assets/styles/styles.css'
import ProjectCard from '../../components/ProjectCard/index'
import styles from './DevProjects.module.css'
function DevProjects() {
    const projects = [
        {url: "https://palcoperto.cosamores.com.br", title: 'Palco Perto (Em desenvolvimento)'},
        {url: "https://www.ankaibrasil.com.br/", title: 'Ankai Brasil'},
        {url: "https://calculadora-test.netlify.app/", title: 'Calculadora comparativa de gastos para veículos à combustão e elétricos'},
        {url: "https://landingeletrico.netlify.app/", title: 'Landing page para o projeto de um carro elétrico'},
        {url: "https://jojogodavelha.netlify.app/", title: 'Jogo da velha em React'},
        {url: "https://ocupaif.netlify.app", title: 'Site do coletivo universitário OcupaIF'},
        {url: "https://github.com/Cosamores/gcp-migration-app", title: 'Ferramenta de migração de dados para o GCP'},
        {url: "https://github.com/Cosamores/LinearNetwork", title: 'Implementação de uma rede neural linear simples'},
        {url: "https://github.com/Cosamores/project-management-app", title: 'Aplicativo de gerenciamento de projetos (frontend)'},
        
    ];

    
    return (
        <div className={styles.projectsContainer}>
            <h1 className={styles.titleAnimated}>WEB PROJECTS</h1>
            <div className={styles.listContainer}>
            <div className={styles.projectsList}>
                {projects.map(project => (
                    <ProjectCard key={project.url} url={project.url} title={project.title} className={styles.projectCard}/>
            
                ))}
            </div>
            </div>
        </div>
    );
}

export default DevProjects;