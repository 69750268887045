import React, { useRef, useState, useEffect } from "react";
import { createPortal } from 'react-dom';
import usePortal from '../../utils/usePortal/usePortal'; // Import the usePortal hook
import styles from "./ContactButton.module.css";
import wppIcon from "../../assets/images/wpp.png";
import phoneIcon from "../../assets/images/phone.png";
import linkedinIcon from "../../assets/images/linkedin.png";
import githubIcon from "../../assets/images/github.png";
import mailIcon from "../../assets/images/mail.png";
import ReactGA from "react-ga4";

const ContactButton = () => {
  const [isModalVisible, setModalVisibility] = useState(false);
  const modalRef = useRef();
  const contactPortal = usePortal('contact-portal'); 

  const toggleModal = () => {
    setModalVisibility(!isModalVisible);
  };

  const handleClickOutside = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      setModalVisibility(false);
    }
  };

  const trackContactClick = (label) => {
    ReactGA.event({
      category: label,
      action: "contact",
    });
  };

  useEffect(() => {
    if (isModalVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isModalVisible]);

  const modal = (
    <div
      ref={modalRef}
      className={`${styles.contactModal} ${isModalVisible ? styles.modalVisible : ""}`}
    >
      <h1 className={styles.title}>LET'S TALK</h1>
      <ul>
        <li className={styles.li}>
          <img src={wppIcon} alt="WhatsApp" />
          <a
            className={styles.link}
            name="whatsapp"
            href="https://wa.me/5511941996781"
            onClick={() => trackContactClick("WhatsApp")}
          >
            Whatsapp
          </a>
        </li>
        <li className={styles.li}>
          <img src={phoneIcon} alt="Phone" />
          <a
            className={styles.link}
            name="phone"
            href="tel:+5511941996781"
            onClick={() => trackContactClick("Phone")}
          >
            +55 11 941996781
          </a>
        </li>
        <li className={styles.li}>
          <img src={mailIcon} alt="Email" />
          <a
            className={styles.link}
            name="email"
            href="mailto:edamareama@gmail.com"
            onClick={() => trackContactClick("Email")}
          >
            edamareama@gmail.com
          </a>
        </li>
        <li className={styles.li}>
          <img src={githubIcon} alt="Github" />
          <a
            className={styles.link}
            name="github"
            href="https://github.com/Cosamores"
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => trackContactClick("GitHub")}
          >
            Github
          </a>
        </li>
        <li className={styles.li}>
          <img src={linkedinIcon} alt="LinkedIn" />
          <a
            className={styles.link}
            name="linkedin"
            href="https://www.linkedin.com/in/diegocosamores/"
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => trackContactClick("LinkedIn")}
          >
            Linkedin
          </a>
        </li>
      </ul>
    </div>
  );

  return (
    <>
      <button
        className={styles.contactFloatBtn}
        onClick={toggleModal}
        aria-label="Contact"
      >
        
      </button>

      {isModalVisible && createPortal(modal, contactPortal)}
    </>
  );
};

export default ContactButton;
