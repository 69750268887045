import styles from "./Main.module.css";
import bgImage from "../../assets/images/background.webp";

import MainSections from "../../components/MainSections/MainSections.jsx";
import Hero from "../../components/Hero";

function MainPage() {
  return (
    <div className={styles.mainContainer}>
      <div className={styles.landingSection}>
        <Hero className={styles.hero} />
        <img
          src={bgImage}
          alt="violin birdw ith open wings background"
          className={styles.backgroundImage}
        />
      </div>
      <div className={styles.sectionsContainer}>
        <MainSections />
      </div>
    </div>
  );
}

export default MainPage;
