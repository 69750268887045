// App.js
import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import ReactGA from "react-ga4";

import Header from "./components/Header/index";
import Main from "./pages/Main/Main.js";
import CV from "./pages/cv/cv.js";
import Blog from "./pages/Blog/blog.js";
import MusicPage from "./pages/Music/index";
import DevProjects from "./pages/DeProjects/devProjects.js";
import ContactButton from "./components/ContactButton/index.js";
import Footer from "./components/Footer/index.js";
import BlogPage from "./pages/BlogPage/index";
import Soon from "./components/Soon/index.js";
import NewMain from "./pages/NewMain/NewMain.js";

import { LanguageProvider } from "./context/LanguageContext.js";
import { BlogProvider } from "./context/BlogContext.js";

import { sendToAnalytics } from "./utils/webVitalsAnalytics.js";
import { onCLS, onLCP, onTTFB, onFCP } from "web-vitals";

const LazyPrivacyPolicy = lazy(()=> import("./pages/PrivacyPolicy/PrivacyPolicy.jsx"));

ReactGA.initialize("G-G11JHEBK9E");
onCLS(sendToAnalytics);
onLCP(sendToAnalytics);
onTTFB(sendToAnalytics);
onFCP(sendToAnalytics);

function App() {
  return (
    <LanguageProvider>
      <BlogProvider>
        <Router>
          <div className="App">
            <Header />
            <ContactButton />
            <Routes>
              <Route path="/" element={<Main />} />
              <Route path="/resume" element={<CV />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/music" element={<MusicPage />} />
              <Route path="/projects" element={<DevProjects />} />
              <Route path="/soon" element={<Soon />} />
              <Route path="/blog/:title" element={<BlogPage />} />
              <Route path="/privacy" element={<Suspense><LazyPrivacyPolicy /></Suspense>} />
              <Route path="/blog/:filename" element={<BlogPage />} />
              <Route path="/lab/cube" element={<NewMain />} />
            </Routes>
            <Footer />
          </div>
        </Router>
      </BlogProvider>
    </LanguageProvider>
  );
}

export default App;
