import React, { useState, useEffect, useRef, useCallback } from "react";
import styles from "./VerticalSlider.module.css";

const VerticalSlider = ({ children }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderRef = useRef(null);
  const touchStartY = useRef(0);

  const goToPreviousSlide = useCallback(() => {
    if (currentSlide > 0) {
      setCurrentSlide(currentSlide - 1);
    }
  }, [currentSlide]);

  const goToNextSlide = useCallback(() => {
    if (currentSlide < React.Children.count(children) - 1) {
      setCurrentSlide(currentSlide + 1);
    }
  }, [currentSlide, children]);

  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === "ArrowUp") {
        goToPreviousSlide();
      } else if (e.key === "ArrowDown") {
        goToNextSlide();
      }
    },
    [goToNextSlide, goToPreviousSlide]
  );

  const handleWheel = useCallback(
    (e) => {
      if (e.deltaY < 0) {
        goToPreviousSlide();
      } else if (e.deltaY > 0) {
        goToNextSlide();
      }
    },
    [goToNextSlide, goToPreviousSlide]
  );

  const handleTouchStart = useCallback((e) => {
    touchStartY.current = e.touches[0].clientY;
  }, []);

  const handleTouchMove = useCallback(
    (e) => {
      const touchEndY = e.changedTouches[0].clientY;
      if (touchStartY.current - touchEndY > 50) {
        goToNextSlide();
      } else if (touchStartY.current - touchEndY < -50) {
        goToPreviousSlide();
      }
    },
    [goToNextSlide, goToPreviousSlide]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    const slider = sliderRef.current;
    slider.addEventListener("wheel", handleWheel);
    slider.addEventListener("touchstart", handleTouchStart);
    slider.addEventListener("touchend", handleTouchMove);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      slider.removeEventListener("wheel", handleWheel);
      slider.removeEventListener("touchstart", handleTouchStart);
      slider.removeEventListener("touchend", handleTouchMove);
    };
  }, [handleKeyDown, handleWheel, handleTouchStart, handleTouchMove]);

  return (
    <div ref={sliderRef} className={styles.sliderContainer}>
      <div
        className={styles.slider}
        style={{ transform: `translateY(-${currentSlide * 100}vh)` }}
      >
        {React.Children.map(children, (child, index) => (
          <div key={index} className={styles.slide}>
            {child}
          </div>
        ))}
      </div>
{/*       <div className={styles.navigation}>
        <button onClick={goToPreviousSlide}>&uarr;</button>
        <button onClick={goToNextSlide}>&darr;</button>
      </div> */}
    </div>
  );
};

export default VerticalSlider;
